.main-wrapper {
  .pricing-details-wrap {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 10px;

    .heading {
      width: max-content;
      font-size: 14px;
      font-family: var(--primary-font);
      color: black;
      text-align: left;
      letter-spacing: 0.5px;
      padding: unset !important;

      .title {
        font-family: var(--primary-font);
        font-size: 13px;
        padding: unset;
        letter-spacing: 0.5px;
        padding-bottom: 10px;
      }
    }

    .details {
      width: max-content;
      font-size: 13px;
      font-family: var(--primary-font) !important;
      color: black;
      text-align: end;
      padding: unset;
      letter-spacing: 0.5px;

      .value {
        font-family: var(--primary-font);
        font-size: 13px;
        padding: unset;
        padding-bottom: 10px;
        letter-spacing: 0.5px;
      }
    }

    .grand-total {
      font-family: var(--primary-font) !important;
      font-size: 13px !important;
      border-top: 1px dotted gainsboro;
      margin-top: 3px;
      padding-top: 10px !important;
    }
  }

  .adv-amt {
    font-size: 14px;
    font-family: var(--primary-font);
    color: black;
    letter-spacing: 0.5px;
    font-weight: 600;
  }

  &.appointment-wrap {
    padding: 15px;
    padding-bottom: 55px;
    background: #ffffffa6;
    width: 100%;
    max-width: 480px;
    margin: auto;
    min-height: calc(100vh - 0px);
    border: 1px solid rgba(0, 0, 0, 0.04);
    transition: all ease-out 0.4s;

    .searched-item-list-wrap {
      overflow: hidden;
    }

    .date-picker-wrap {
      .react-datepicker__month-container {
        width: 100%;
      }

      .react-datepicker__day,
      .react-datepicker__month-text,
      .react-datepicker__quarter-text,
      .react-datepicker__year-text {
        font-family: var(--primary-font);
        cursor: pointer;
        border: 1px solid #e6f4ff;
        border-radius: 5px;
        font-size: 14px;
        margin: 4px;
      }

      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        color: #000;
        display: inline-block;
        width: calc(100% / 7 - 10px);
        line-height: 37px;
        text-align: center;
        margin: 5px;
      }

      .react-datepicker__day--selected:hover,
      .react-datepicker__day--in-selecting-range:hover,
      .react-datepicker__day--in-range:hover,
      .react-datepicker__month-text--selected:hover,
      .react-datepicker__month-text--in-selecting-range:hover,
      .react-datepicker__month-text--in-range:hover,
      .react-datepicker__quarter-text--selected:hover,
      .react-datepicker__quarter-text--in-selecting-range:hover,
      .react-datepicker__quarter-text--in-range:hover,
      .react-datepicker__year-text--selected:hover,
      .react-datepicker__year-text--in-selecting-range:hover,
      .react-datepicker__year-text--in-range:hover,
      .react-datepicker__day--keyboard-selected,
      .react-datepicker__month-text--keyboard-selected,
      .react-datepicker__quarter-text--keyboard-selected,
      .react-datepicker__year-text--keyboard-selected {
        background-color: var(--primary-color) !important;
        color: #fff !important;
      }

      .react-datepicker__day--disabled {
        opacity: 0.2;
      }
    }
  }
  &.app-cal-wrapper {
    background-color: #fff;
  }

  &.booked-slot-appointment-wrap {
    height: auto;
    min-height: 35vh;

    .date-picker-wrap {
      .date-picker-button {
        width: max-content;
        border-radius: 5px;
        border: unset;
        outline: none;
        font-size: 14px;
        letter-spacing: 0.2px;
        box-sizing: border-box;
        background: white;
        text-align: left;
        color: black;
      }

      .react-datepicker__navigation {
        // display: none;
      }

      .react-datepicker__portal .react-datepicker__current-month,
      .react-datepicker__portal .react-datepicker-time__header {
        // display: none;
      }

      .react-datepicker-popper[data-placement^="top"]
        .react-datepicker__triangle,
      .react-datepicker-popper[data-placement^="bottom"]
        .react-datepicker__triangle {
        display: none;
      }

      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        // display: none;
      }

      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        width: 33px;
      }
    }

    .selected-service-list-wrap {
      .service-wrap {
        position: relative;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        padding: 12px;

        .service-name {
          font-weight: 600;
        }

        .staff-slot-wrap {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 10px;

          .staff-list {
            width: 100%;

            &.slot-list {
              width: 70%;
            }

            .value-wrap {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
              width: 100%;

              .staff-list-wrapper {
                left: 0;
                top: 60px;

                &.error {
                  background: #f4dfdf;
                  color: #950b0b;
                }
              }

              .slot-time-outer {
                left: -130px;
                top: 60px;
                right: 0;
                padding: 4px;
              }

              .guided-label {
                min-height: 35px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border: 1px solid rgba(222, 225, 236, 0.6901960784);
                border-radius: 5px;
                background: rgba(222, 225, 236, 0.6901960784);
                padding: 6px;
                width: 100%;
              }

              .error {
                .guided-label {
                  color: red;
                  background-color: rgba(255, 0, 0, 0.154);
                }
              }
            }

            .label {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 10px;

              .staff-input {
                border: 1px solid #dee1ecb0;
                border-radius: 5px;
                background: #dee1ecb0;
                padding: 6px;
                width: 100%;
              }
            }
          }
        }

        .service-cancel-icon {
          position: absolute;
          top: 6px;
          right: 6px;
          width: 28px;
          height: 28px;
          padding: 2px;
        }
      }
    }
  }

  .text {
    width: 60%;
    margin-top: 15px;
  }

  .username {
    font-family: var(--primary-font);
    margin-bottom: 3px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.3px;
  }

  .usernumber {
    font-weight: 500;
    font-size: 13px;
    font-family: var(--primary-font);
    letter-spacing: 0.3px;
  }

  .heading {
    padding-top: 10px;
    font-size: 14px;
    font-family: var(--primary-font);
    letter-spacing: 0.3px;
    font-weight: 700;
    // color: #757575;
  }

  .modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 30px;
    height: 30px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #dee1ec;
    font-size: 12px;
    border-radius: 20px;
    color: var(--primary-color);
    padding: 6px;
  }

  .wrap-content {
    max-height: calc(100vh - 154px);
    overflow: auto;
    @include hide-scrollbar;
  }

  .input-wrp {
    margin: 4px 0 0 0 !important;
  }

  .input-wrap {
    position: relative;
    width: 100%;

    .inputbox {
      width: 100%;
      background: #f0f0f0;
      border: none;
      border-radius: 4px;
      padding: 10px;
      outline: none;
      font-size: 13px;
      resize: none;

      // border: 1px solid var(--primary-color);
      &.instr {
        height: 80px;

        &.instrShare {
          width: 100%;
        }
      }
    }

    .instrFileInputButton {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      gap: 10px;
      margin: 6px 0;

      .primary-btn {
        padding: 10px;
        font-size: 11px;
      }

      .add-img-note {
        font-size: 12px;

        &.error {
          color: #f14a4a;
        }
      }
    }

    .instrImgScrollContainer {
      width: 100%;
      display: block;
      position: relative;

      .instrImgScroll {
        width: 100%;
        display: block;

        .instrImgContainer {
          margin-right: 10px;
          height: 80px;
          position: relative;
          display: inline-flex;

          .instrImageDelIcon {
            position: absolute;
            top: 1px;
            right: -1px;
            height: 20px;
            fill: white;
            stroke: black;
            strokewidth: 1px;
          }

          .instrImg {
            height: 100%;
            border-radius: 5px;
          }
        }
      }
    }

    .input-icon {
      position: absolute;
      right: 12px;
      width: 20px;
      height: 20px;
      top: 12px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .service-search-input-wrap {
      position: relative;
      padding-bottom: 10px;
    }

    .add-services-wrap {
      z-index: 1;
      transition: all ease-out 0.3s;
      overflow-y: auto;
      height: calc(100vh - 180px);
      overflow-x: hidden;
      padding: 5px;
      @include hide-scrollbar;

      .items-cat-wrap {
        border: 1px solid #eee;
        padding: 12px 12px 0;
        margin-bottom: 10px;
        border-radius: 8px;
        box-shadow: 2px 3px 11px 0 rgb(235 237 240 / 73%);

        .cat-name {
          width: 100%;
          float: left;
          font-size: 13px;
          margin-bottom: 10px;
          font-weight: 700;
          letter-spacing: 0.1px;
          color: black;
        }

        .service-cover {
          width: 100%;
          display: flex;
          padding: 10px 5px;
          align-items: center;
          border-radius: 0px;
          margin: unset;
          margin-bottom: 4px;
          padding-right: 0;

          &:not(:last-child) {
            border-bottom: 1px solid #ebedf087;
          }

          &:active {
            .service-price,
            .service-name {
              color: var(--primary-color);
            }
          }
        }

        &.has-sub-category {
          .items-cat-wrap {
            box-shadow: unset;
            border: unset;
            padding: 6px;
          }
        }
      }

      .add {
        color: var(--primary-color);
        display: flex;

        .add-button {
          width: 18px;
          height: 18px;
          float: left;
          border: 1px solid var(--primary-color);
          border-radius: 4px;
          font-size: 18px;
          font-weight: 500;
          color: var(--primary-color);
          background-color: #fff;
          display: flex;
          justify-content: center;
          transition: all ease-out 0.3s;
          align-items: center;
          margin-left: 5px;
        }
      }

      .not-found {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 17px;
        color: var(--primary-color);
        transition: all ease-out 0.3s;
      }
    }
  }

  .book-wrap {
    position: fixed;
    width: 100%;
    background-color: rgb(255 255 255 / 90%);
    color: #000000;
    font-size: 13px;
    bottom: 0;
    padding: 5px 15px;
    line-height: 28px;
    background: #fdf9fd00;
    -webkit-backdrop-filter: saturate(180%) blur(7px);
    backdrop-filter: saturate(180%) blur(7px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 480px;
    left: 50%;
    transform: translateX(-50%);

    .total-wrap {
      width: auto;

      .title {
        font-size: 15px;
        font-family: var(--primary-font);
        padding: unset;
      }

      .value {
        padding-left: 5px;
        font-size: 15px;
        font-family: var(--primary-font);
      }

      .info {
        margin-left: 5px;
        width: 20px;
        height: 20px;
        margin-bottom: 3px;
        color: var(--primary-color-alpha);

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .info-color {
        svg {
          path {
            fill: transparent;
            color: black;
          }

          circle {
            color: black !important;
          }
        }
      }
    }
  }

  .backdrop-modal-wrapper {
    .backdrop-modal-content {
      &.total-wrap {
        .pricing-details-wrap {
          display: flex;
          justify-content: flex-start;

          .heading {
            width: 60%;
          }

          .details {
            width: 40%;
          }

          .grand-total {
            font-family: var(--primary-font) !important;
            font-size: 13px !important;
            border-top: 1px dotted gainsboro;
            margin-top: 3px;
            padding-top: 10px !important;
          }
        }
      }

      .wrap-details {
        box-shadow: rgb(0 0 0 / 16%) 0px 4px 16px;
        background-color: rgb(255, 255, 255);
        padding: 10px;
        margin-top: 20px;
        z-index: 1;

        .popup-close {
          position: absolute;
          right: 26px;
          width: 26px;
          height: 26px;
          background: #ebedf0;
          border-radius: 25px;
          padding: 5px;
        }
      }

      .input-wrapper {
        display: flex;
        justify-content: center;
        align-items: baseline;

        .input-heading {
          padding: 10px 0px;
          width: 120px;
        }

        .input-style {
          height: 35px;
          border-radius: 5px;
          border: none;
          outline: none;
          padding: 1px 10px 0;
          font-size: 14px;
          box-sizing: border-box;
          border: 1px solid gray;
          margin: 5px 0 10px 5px;
        }
      }
    }

    &.instrImageDelConfirmModal {
      .backdrop-modal-content {
        div {
          display: flex;
        }

        .imageHeightLimiter {
          height: 300px;
          justify-content: center;
          margin-bottom: 20px;

          img {
            border: black solid 1px;
            border-radius: 7px;
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }

        .buttonContainer {
          justify-content: flex-end;

          button.primary-btn:first-of-type {
            margin-right: 20px;
          }
        }
      }
    }
  }

  .appointment-checkout-wrapper {
    .sub-heading {
      font-weight: 600;
    }

    .backdrop-modal-content {
      padding: unset;
    }

    .appointment-checkout-content {
      max-height: 600px;
      padding: 15px;
      padding-bottom: 85px;
      overflow: auto;
      @include hide-scrollbar;

      .appt-for-wrap {
        .pricing-details-wrap {
          justify-content: flex-end;
        }
      }

      .service-details {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        &.heading {
          border-bottom: 1px solid #dee1ec;
          padding: 0;
          margin-bottom: 8px;

          .service-name {
            font-weight: 600;
          }
        }

        .service-name {
          width: 100%;
          font-size: 13px;
          color: black;
          font-family: var(--primary-font);
          letter-spacing: 0.3px;
          padding-bottom: 2px;
          text-align: left;
        }

        .expert {
          margin-left: auto;
          text-align: right;
        }

        .slot {
          margin-left: auto;
          text-align: right;
        }
      }
    }

    .heading {
      text-align: center;
      font-size: 16px;
      font-family: var(--primary-font);
      letter-spacing: 0.5px;
      padding-top: 10px;
    }

    .date-time {
      .date {
        font-size: 13px;
        font-family: var(--primary-font);
        color: black;
        letter-spacing: 0.5px;
      }
    }

    .services-list-wrap {
      .service-name {
        font-size: 13px;
        font-family: var(--primary-font);
        color: black;
        letter-spacing: 0.5px;
        padding-bottom: 5px;
      }

      &.disposables-list {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;

        .service-name {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    .appt-for-wrap {
      .selected-profile {
        .name {
          font-size: 13px;
          font-family: var(--primary-font);
          color: black;
          letter-spacing: 0.5px;
        }
      }

      .profile-list {
        overflow: auto;
        white-space: nowrap;
        display: flex;
        padding: 10px 0;
        @include hide-scrollbar;

        .profile {
          border: 1px solid gray;
          border-radius: 15px;
          padding: 5px 10px;
          margin-right: 10px;
          font-size: 12px;
          font-family: var(--primary-font);
          color: black;
          letter-spacing: 0.5px;

          &.active {
            border-color: var(--primary-color);
            color: var(--primary-color);
          }
        }
      }

      .add-profile {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;

        div {
          font-size: 12px;
          font-family: var(--primary-font);
          color: var(--primary-color);
          letter-spacing: 0.5px;
        }
      }
    }

    .inputbox {
      width: 100%;
      border: unset;
      background: #fbfdff;
      border-bottom: 1px solid gray;
      border-radius: 4px;
      padding: 10px;
      outline: none;
      font-size: 13px;
    }

    .confirm-btn-wrap {
      position: fixed;
      bottom: 0;
      transform: translateX(-50%);
      left: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      width: 100%;
      background: white;
      padding: 10px;

      .primary-btn {
        min-width: max-content;
        padding: 10px 20px;
      }
    }
  }

  .member-modal {
    padding: 15px;

    .member-form {
      .input-wrap {
        margin: 0 0 13px;
        position: relative;

        .error {
          font-size: 12px;
        }

        span {
          &.label {
            font-family: var(--primary-font);
            color: #b7bac1;
            font-size: 11px;
            display: block;
            padding: 0 6px;
            padding-bottom: 3px;
            width: auto;
            background: #ffffff;
            position: absolute;
            top: -6px;
            z-index: 1;
            left: 5px;
          }
        }

        input {
          width: 100%;
          background: #ffffff82;
          border: none;
          border-radius: 5px;
          padding: 11px;
          outline: none;
          border: 1px solid #dee1ec;
          color: dimgray;
          font-size: 11px;
          font-family: var(--primary-font);
          letter-spacing: 0.3px;

          &:focus,
          &:focus-visible {
            transition: all ease-out 0.3s;
            border-color: var(--primary-color) !important;
          }

          &.error {
            border-color: red !important;
          }
        }
      }
    }

    .form-btn-wrap {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 20px;

      .btn {
        padding: 7px 10px;
        width: 130px;
        margin: 5px;
        background-color: var(--primary-color);
        border: unset;
        border-radius: 20px;
        color: white;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.5px;
        font-family: var(--primary-font);
        border: 1px solid var(--primary-color);
      }
    }
  }

  .slot-time-outer {
    // overflow: auto;
    // white-space: nowrap;
    .sub-heading {
      position: sticky;
      left: 0;
      top: 0;
    }
    .heading-index {
      background-color: #fff;
    }

    padding-bottom: 7px;
  }
  .time-wrap {
    padding-top: 0;
    height: calc(60vh - 200px);
  }

  .selected-service-list-wrap {
    .input-wrap {
      padding-bottom: 4px;
    }

    .service-wrap {
      display: flex;
      justify-content: space-between;
      border: 1px solid #ddd;
      border-radius: 4px;
      margin-bottom: 7px;
      padding: 7px;
      align-items: center;

      .service-details {
        .service-name {
          font-size: 13px;
          color: black;
          font-family: var(--primary-font);
          width: auto;
          letter-spacing: 0.3px;
          padding-bottom: 2px;
        }

        .variations-wrap {
          font-size: 12px;
          color: #363636;
          font-family: var(--primary-font);
          letter-spacing: 0.3px;
          width: auto;
        }
      }

      .service-cancel-icon {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #dee1ec;
        font-size: 12px;
        border-radius: 20px;
        color: var(--primary-color);
        padding: 6px;

        svg {
          height: 100%;
          width: 100%;
          color: black;
        }
      }
    }

    .btn-wrap {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 10px;

      .btn {
        font-size: 13px;
        font-weight: 500;
        padding: 10px;
        color: black;
        border: 1px solid var(--primary-color-alpha);
        border-radius: 5px;
      }
    }
  }

  .slot-wrap {
    display: inline-block;
    width: calc(100% / 3 - 10px);
    padding: 8px 3px;
    margin: 5px;
    border-radius: 5px;
    text-align: center;
    border: 1px solid #ddd;
    transition: all ease-out 0.3s;
    font-size: 12px;
    color: black;
    letter-spacing: 0.5px;

    &.active {
      background: var(--primary-color);
      color: var(--primary-text-color);
      transition: all ease-out 0.3s;
      position: sticky;
      left: -10px;
      right: 0;
    }
  }

  .date-wrap {
    .sub-heading {
      position: sticky;
      left: 0;
      top: 0;
    }

    overflow: auto;
    white-space: nowrap;
    max-width: 100%;
    padding-bottom: 7px;
    @include hide-scrollbar;
    .app-date-wrap {
      margin-top: 15px;
    }
  }

  .service {
    width: fit-content !important;
    padding: 4px;
  }

  .expert-list {
    .sub-heading {
      padding-bottom: 4px;
      font-size: 13px;
    }

    .input-icon {
      top: 43px;
      transition: all ease-out 0.3s;
      transform: rotate(0);
      width: 21px;
      height: 20px;
      right: 21px;

      &.active {
        transform: rotate(90deg);
        transition: all ease-out 0.3s;
      }
    }

    .expert-details {
      padding: 5px;

      .staff-name {
        font-size: 14px;
        font-family: var(--primary-font);
      }
    }
  }
}

.appt-conf-details {
  text-align: left;

  .heading {
    padding-bottom: 5px;
  }
}

.add-member {
  padding: 5px 10px;
  width: 130px;
  margin: 5px;
  background-color: var(--primary-color);
  border: unset;
  border-radius: 20px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-family: var(--primary-font);
  border: 1px solid var(--primary-color);
}

.top-icon {
  width: 46%;
  display: flex;
  margin: 10px auto 10px auto;
  color: #424040;
  font-family: var(--primary-font);
}

.instruction {
  margin: 20px 0 !important;
  text-align: center !important;

  &.appt-status-note {
    font-weight: 700;
    font-family: "poppins-medium";
    font-size: 14px;
    letter-spacing: 0.2px;
  }
}

.alignment {
  justify-content: space-around !important;
}

.backwrap {
  position: fixed;
  top: 0;
  z-index: 1;
  box-shadow: 0px 0px 0.9px rgb(0 0 0 / 3%), 0px 0px 2.9px rgb(0 0 0 / 4%),
    0px 0px 13px rgb(0 0 0 / 7%);
  width: 100%;
  left: 0;
  right: 0;
  max-width: 480px;
  left: 50%;
  transform: translateX(-50%);

  .backwrap-content {
    padding: 20px;
    background: white;
    justify-content: flex-start;
    display: flex;
    font-size: 15px;
    align-items: center;
    letter-spacing: 0.3px;
    font-weight: 600;
  }

  .icon {
    width: 15px;
    margin-right: 12px;
    height: 15px;
  }
}
.actions {
  display: flex;
}

.slot-btn-wrap {
      position: fixed;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.9);
        color: #000000;
        font-size: 13px;
        bottom: 0;
        padding: 5px 15px;
        line-height: 28px;
        background: rgba(253, 249, 253, 0);
        -webkit-backdrop-filter: saturate(180%) blur(7px);
        backdrop-filter: saturate(180%) blur(7px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 480px;
        left: 50%;
        transform: translateX(-50%);
}
.app-slot-align {
  gap: 20px;
}

.cancel-buttons{
  gap: 10px;
    display: flex;
    flex-direction: row-reverse;
}
