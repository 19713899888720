.custom-img-slider-wrap {
  width: 100%;

  #slider {
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .slide {
    width: 100%;
    height: 100%;
    // max-height: 350px;
    display: none;
    animation-name: fade;
    animation-duration: 1s;
    justify-content: center;
    align-items: center;

    &.vdo-slide {

      // video {
      //   width: 100%;
      //   height: 100%;
      // }
      .vdo-wrap {
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
  }

  @keyframes fade {
    from {
      opacity: 0.5;
    }

    to {
      opacity: 1;
    }
  }

  .controls {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 2px;
    border-radius: 5px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;

    &:hover {
      background: white;
      transition: 0.3s;
    }

    &:active {
      color: grey;
    }
  }

  #left-arrow {
    left: 10px;
  }

  #right-arrow {
    right: 10px;
  }

  @media (max-width: 576px) {
    #slider {
      width: 100%;
    }
  }

  .thumbnail-wrap {
    display: flex;
    justify-content: center;
    gap: 15px;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: 10px 0;

    .thumbnail {
      height: auto;
      overflow: hidden;
      width: auto;
      transition: all 0.3s ease-in;
      height: 30px;
      width: 35px;
      border: 1px solid gray;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.active {
        transition: all 0.3s ease-in;
        border-color: var(--primary-color);
        transform: scale(1.4);
      }

      img {
        width: 100px;
      }
    }
  }
}