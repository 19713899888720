.pdp-modal-wrap {
  max-height: -webkit-fill-available;
  z-index: 13 !important;
  border-radius: unset;
  position: relative;
  width: 100%;
  max-width: 480px;
  background-color: white;
  z-index: 2;
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 10px 0px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  transition: all ease-out 0.3s;
  left: 50%;
  transform: translateX(-50%);

  .modal-close {
    width: 35px;
    height: 35px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ebedf0de;
    //  #ebedf0a3;
    font-size: 12px;
    border-radius: 10px;
    color: black;
    padding: 7px;

    svg {
      color: black;
      height: 100%;
      width: 100%;
    }

    &:active {
      background-color: var(--primary-color-alpha);
      box-shadow: 0 2px 10px var(--primary-color-alpha);
      transform: scale(1.04);
    }
  }

  .modal-cart {
    position: relative;

    .cart-item-count {
      position: absolute;
      right: -8px;
      top: -7px;
      background: var(--primary-color);
      color: white;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      font-size: 12px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: var(--primary-font);
      font-weight: 500;
      letter-spacing: 0.3px;
    }
  }

  .vdo-icon {
    top: 60px;
    right: 15px;
    left: unset;
  }

  .pdp-page-content {
    width: 100%;
    border-radius: 10px 10px 0 0;
    height: calc(calc(100vh - (100vh - 100%)));
    overflow: auto;
    background: white;
    z-index: 1;
    position: absolute;
    bottom: 0;
    box-shadow: unset;
    padding-bottom: 70px;

    // max-height: calc(100vh - (100vh - 100%));
    .image-gallery-slide {
      height: 100%;
      max-height: unset;
    }

    .action-btn-wrap {
      top: 0;
      z-index: 5;
      position: fixed;
      width: 100%;
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      padding: 15px 0 15px 15px;

      .secondary-action {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .modal-close {
          margin-right: 15px;
          color: black;
        }
      }
    }

    @include hide-scrollbar;

    .image-gallery-swipe {
      filter: unset;
    }
  }

  .serv-pdp-servtypedesc {
    width: 100%;
    font-size: 12px;
    font-weight: 300;
    font-family: var(--primary-font);
    color: gray;
    margin-bottom: 5px;
    letter-spacing: 0.5px;
  }

  .serv-pdp-servtypedesc span {
    color: var(--primary-color);
    margin-left: 5px;
  }

  .slider-wrap {
    // width: calc(100% - 1px);
    overflow: auto;
    height: 100%;
    @include hide-scrollbar;

    .zoom-div {
      transition: all ease-out 0.3s;
      width: 100%;
      // height: 30vh;
    }
  }

  .prodpdpbanner {
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    margin-bottom: 15px;
    position: relative;

    // height: calc(66vh - 54px);
    .zoom-in,
    .zoom-out {
      position: absolute;
      top: 55px;
    }

    .zoom-in {
      top: 100px;
    }
  }

  .alice-carousel__dots {
    bottom: 50px !important;
  }

  .alice-carousel__dots-item:not(.__custom) {
    border: 0px solid transparent !important;
    width: 6px !important;
    height: 6px !important;
  }

  .alice-carousel__dots-item:not(.__custom):hover,
  .alice-carousel__dots-item:not(.__custom).__active {
    background-color: var(--primary-color) !important;
    border: 0px solid #000000 !important;
    width: 6px !important;
    height: 6px !important;
    margin-bottom: 0px;
  }

  .prod-pdp-details {
    width: 100%;
    background-color: #ffffff;
    position: relative;
    padding: 15px;
    z-index: 2;
    height: auto;
    overflow: auto;
  }

  .prod-pdp-off {
    width: 100%;
    float: left;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    color: var(--primary-color);
  }

  .prod-pdp-nameprice {
    width: 100%;
    margin-bottom: 15px;
  }

  .prod-pdp-name {
    width: 70%;
    float: left;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--primary-font);
    letter-spacing: 0.2px;
  }

  .prod-pdp-price {
    width: 30%;
    float: right;
    transition: all ease-out 0.3s;
    text-align: right;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--primary-font);
    letter-spacing: 0.3px;

    span {
      font-size: 14px;
      font-weight: 500;
      color: #c2c2c2;
      display: inline-block;
      margin-right: 5px;
      transition: all ease-out 0.3s;
      text-decoration: line-through;
    }
  }

  .prod-pdp-sizetitle {
    width: 100%;
    float: left;
    font-size: 10px;
    margin-bottom: 5px;
  }

  .prod-pdp-size {
    width: 100%;
    float: left;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ebebeb;
  }

  .prod-pdp-size span {
    display: inline-block;
    border-radius: 5px;
    font-size: 10px;
    font-weight: 500;
    padding: 8px 10px;
    background-color: var(--primary-color-alpha);
    color: #000000;
    float: left;
    font-family: 'poppins-regular';
    letter-spacing: 0.3px;
    margin-right: 10px;
  }

  .prod-pdp-size span.active {
    font-weight: 600;
    color: white;
    background-color: var(--primary-color);
  }

  .MuiAccordionSummary-root svg {
    fill: #ccc;
    font-size: 30px;
    margin-right: 0;
  }

  .accor-title {
    font-size: 14px;
  }

  .MuiAccordionSummary-root {
    padding: 0px 0px;
    min-height: 30px;
  }

  .MuiAccordionSummary-content {
    margin: 5px 0;
  }

  .MuiIconButton-root {
    padding: 0px 12px !important;
  }

  .MuiAccordionDetails-root {
    font-size: 12px !important;
    font-weight: 300 !important;
  }

  .MuiAccordion-root:before {
    height: 0px !important;
  }

  .itemcounter {
    width: 100%;
    float: left;
    padding: 15px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    background: white;
    /* From https://css.glass */
    // background: rgba(255, 255, 255, 0.28);
    border-radius: 16px 16px 0 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

    .request-quote-btn {
      padding: 5px 25px;
      float: left;
    }

    .current-stock-wrap {
      text-align: right;
      font-style: italic;
      padding-bottom: 10px;
      letter-spacing: 0.2px;
      font-size: 13px;
      color: var(--primary-color);
      font-weight: 500;

      &.shake {
        color: red;
      }
    }
  }

  .itemcounterinner {
    float: right;
    transition: all ease-out 0.3s;
    width: auto;
  }

  .addtocartbtn {
    width: auto;
    float: right;
    height: 40px;
    background-color: var(--primary-color);
    color: #fff;
    border-radius: 5px;
    padding: 0 15px;
    transition: all ease-out 0.3s;
    cursor: pointer;
    font-family: var(--primary-font);
    letter-spacing: 0.2px;
    box-shadow: 2px 3px 10px var(--primary-color-alpha);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &.request-quote-btn {
      margin-right: 15px;
    }

    &.stock-unavailable {
      background-color: white;
      border-color: var(--primary-color);
      color: var(--primary-color);
      box-shadow: unset;
    }
  }

  .counterbuttons {
    transition: all ease-out 0.3s;
    min-width: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .countnum {
    float: left;
    width: 30px;
    text-align: center;
    font-size: 18px;
    transition: all ease-out 0.3s;
    line-height: 30px;
  }

  .countclick {
    float: left;
    border: 1px solid var(--primary-color-alpha);
    font-weight: 500;
    transition: all ease-out 0.3s;
    filter: drop-shadow(4px 2px 6px var(--primary-color) 59);
    width: 35px;
    height: 35px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ebedf0de;
    font-size: 18px;
    border-radius: 10px;
    color: var(--primary-color);
    padding: 7px;
  }

  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }

  .description {
    padding-left: 0;
    color: #777777;
  }
}

.vdo-details-backdrop-wrap {
  padding: unset !important;
  top: 50% !important;
  bottom: 50% !important;
  transform: translate(-50%, -50%) !important;
  left: 50% !important;
  right: 50% !important;
  border-radius: 10px !important;

  .modal-close {
    left: 50% !important;
    right: 50% !important;
    top: -25px !important;
    transform: translate(-50%, -50%) !important;
  }

  .vdo-details-frame {
    overflow: hidden;
  }

  .vdo-frame {
    width: 100%;
    border-radius: 10px;
  }
}

.serv-pdp-servtypedesc-list {
  margin-bottom: 10px;

  span {
    padding-right: 2px;
  }
}

.social-sharing-modal {
  .modal-close {
    right: 10px !important;
    left: unset !important;
  }

  .icons-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 10px;
    width: 100%;
    padding: 20px 0;
    padding-top: 10px;
  }

  .icon-wrap {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: #f8fafb;
    transition: background-color 0.2s ease;
    border-radius: 10px;
    padding-top: 16px;

    .icon {
      width: 40px;
      height: 40px;
    }

    .name {
      font-size: 12px;
      font-weight: 600;
      padding: 0 0 10px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .preview-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #ebedf0;
    padding: 5px;
    border-radius: 8px;
    background: #dee1ec;
    border-radius: 7px;
    box-shadow: 0 4px 30px #ebedf047;
    margin: 20px 0;
    transition: all ease-out 0.3s;

    &:active {
      box-shadow: 0 2px 10px var(--primary-color-alpha);
      transition: all ease-out 0.3s;
      transform: scale(1.04);
    }

    .img-wrap {
      width: 80px;
      height: 80px;
      padding: 10px;

      img {
        border-radius: 3px;
        width: 100%;
      }
    }

    .title-wrap {
      width: 65%;
      padding-left: 7px;

      .title {
        text-align: left;
        padding: unset;
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 10px;
      }

      .desc {
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }
  }
}