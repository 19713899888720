.confirmation-modal {
  padding: 20px 20px 25px;
  text-align: center;
  position: relative;

  .heading {
    text-align: center !important;
  }

  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
    img {
      height: 100%;
      width: 100%;
    }
  }

  .body-text {
    font-family: var(--primary-font);
    padding: 25px 0 35px;
    font-size: 14px;
    letter-spacing: 0.3px;
  }
}
