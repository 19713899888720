// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: "atlantika";
  src: url("/assets/fonts/Atlantika.otf"); // pattern: /directoryName/file.extension
}

@font-face {
  font-family: "bringHeart";
  src: url("/assets/fonts/FontsFree-Net-BringHeart-Regular.ttf");
}

@font-face {
  font-family: "poppins-light";
  src: url("/assets/fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "poppins-medium";
  src: url("/assets/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "poppins-regular";
  src: url("/assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "mvboli";
  src: url("/assets/fonts/mvboli.ttf");
}

@font-face {
  font-family: "pacifico-regular";
  src: url("/assets/fonts/Pacifico-Regular.ttf");
}
@font-face {
  font-family: "amita-regular";
  src: url("/assets/fonts/Amita-Regular.ttf");
}
