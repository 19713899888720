.horizontal-product-card-wrap {
  &.out-of-stock {
    border: 1px solid rgba(255, 0, 0, 0.427);
  }

  width: 100%;
  position: relative;
  margin-bottom: 4px;
  border-radius: 6px;
  border: 1px solid #fcfafb;
  transition: all ease-out 0.3s;
  background: #ffffff9c;
  border-radius: 8px;
  box-shadow: #ebedf0ba 2px 3px 11px 0px;
  border: 1px solid #ded9d9b0;
  float: left;

  &:hover {
    transform: scale(0.98);
    /* Scaling button to 0.98 to its original size */
    // box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    /* Lowering the shadow */
  }

  .product-cover {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    gap: 10px;
  }

  .out-of-stock {
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0.2px;
    text-align: right;
    padding: 10px 0 0;
    color: red;
  }

  .prod-left {
    width: 130px;
    min-height: 116px;
    // height: 116px;
  }

  .prod-right {
    width: 100%;
    padding-right: 10px;

    .quantity-price-wrap {
      width: 100%;
      display: flex;
      position: relative;
    }
  }

  .prod-img-cover {
    width: 130px;
    height: 100%;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 5px 20px #c1bbbb24;
  }

  .prod-img-cover img {
    width: 100%;
    height: 100%;
  }

  .prod-name {
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    margin: 5px 0 5px;
    // height: 38px;
    // line-height: 18px;
  }

  .prod-name span {
    color: var(--primary-color);
    display: inline-block;
    font-weight: 500;
    padding-left: 10px;
  }

  .price-wrap {
    // width: auto;
    // display: inline-block;
    position: absolute;
    right: 0;
    font-weight: 600;
  }

  .prod-desc {
    width: 100%;
    float: left;
    font-size: 13px;
    font-weight: 300;
    color: #777777;
    margin: 0px 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .prod-sizeprice {
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 8px 0;

    .prod-size {
      width: auto;

      span {
        display: inline-block;
        border-radius: 5px;
        font-size: 10px;
        font-weight: 600;
        padding: 8px;
        background-color: var(--primary-color-alpha);
        color: #000000;
        float: left;
        font-family: 'poppins-regular';
        letter-spacing: 0.3px;
      }
    }

    .prod-sale-price {
      font-size: 13px;
      font-weight: 600;

      span {
        font-size: 14px;
        font-weight: 500;
        color: #c2c2c2;
        display: inline-block;
        margin-right: 5px;
        text-decoration: line-through;
      }
    }

    .add-btn-wrap {
      min-width: fit-content;
      font-size: 12px;
      font-weight: 500;
      margin-left: auto;

      .btn {
        border-radius: 4px;
        padding: 5px 7px;
        background-color: var(--primary-color-alpha);
      }
    }

    &.with-add-btn {
      justify-content: flex-start;
      align-items: center;
      gap: 5px;

      .prod-size {
        span {
          font-weight: 400;
          background-color: unset;
          padding-right: 2px;
        }
      }
    }
  }

  .prod-price {
    position: relative;
  }

  .prod-price::after {
    content: '';
    width: 72%;
    height: 2px;
    background: var(--primary-color);
    position: absolute;
    left: 21px;
    top: 8px;
    border-radius: 50%;
    transform: rotate(155deg);
  }

  .service-list-cover {
    width: 100%;
    float: left;
    padding: 20px 15px 0px;
  }
}

.service-list-cover {
  &.product {
    padding: 0 !important;
    box-shadow: unset !important;
    border: unset !important;
    margin: 0 !important;
  }
}