.privacy-wrap {
  background-color: transparent;
  margin: 14px;

  span {
    background-color: transparent !important;
  }

  .glass-card {
    padding: 10px;
    margin-bottom: 10px;
  }
}

.title {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 0;
  margin-bottom: unset;
}