.con-main-wrap {
  font-family: 'Poppins', sans-serif;
  width: 100%;
  padding: 15px 0 0;
  background-color: white;
  color: #3f3d55;

  .con-wrap {
    width: fit-content;
    margin: auto;
    text-align: center;
    width: 100%;

    .image-wrap {
      height: 100px;

      img {
        width: auto;
        height: 100%;
      }
    }

    .cart-logo {
      width: 100%;
    }

    .cart-status {
      font-weight: 600;
      font-size: 14px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .cart-subtext {
      font-size: 10pt;
      padding: 5px 0;
    }
  }

  .date-id-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .order-details-wrap {
    padding: 16px;
    float: left;
    width: 100%;

    .heading {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .item-wrap {
      border-bottom: 1px solid #9e9e9e;
      margin-bottom: 10px;
      padding-bottom: 6px;

      .name {
        width: 70%;
        display: inline-block;
        float: left;
      }

      .price {
        width: 30%;
        display: inline-block;
        float: right;
        text-align: right;
      }

      .variation-wrap {
        .name {
          color: var(--primary-color);
        }
      }
    }

    .order-total {
      font-size: 15px;
      text-align: right;
      margin-top: 5px;
    }

    .cart-button-wrap {
      text-align: center;
      position: sticky;
      bottom: 0;
      text-align: center;
      background-color: white;

      .cart-button {
        border: unset;
        outline: unset;
        border-radius: 5px;
        padding: 10px 50px;
        margin: 15px 0;
        background-color: var(--primary-color);
        color: white;
        font-weight: 500;
      }
    }
  }

  .order-confirmation {
    padding: 0 10px;

    .appointment-checkout-content {
      border: 1px solid;
      border-radius: 5px;
      padding: 6px;

      .card {
        box-shadow: unset;
        margin: unset;
        border-bottom: 1px solid rgb(180, 173, 173);
        border-radius: 0;
      }

      .date-time {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
      }

      .service-details {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr;
        width: 100%;

        &.heading {
          border-bottom: 1px solid #dee1ec;
          padding: 0;
          margin-bottom: 8px;

          .service-name {
            font-weight: 600;
          }
        }

        .service-name {
          width: 100%;
          font-size: 13px;
          color: black;
          font-family: var(--primary-font);
          letter-spacing: 0.3px;
          padding-bottom: 2px;
          text-align: left;
        }

        .expert {
          margin-left: auto;
          text-align: right;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }

        .slot {
          margin-left: auto;
          text-align: right;
          width: max-content;
          margin-left: 10px;
          min-width: max-content;
        }
      }
    }

    .services-list-wrap {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 5px;
      width: 100%;
    }

    .service-name {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }

    .pricing-details-wrap {
      justify-content: flex-end;

      .heading {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
      }
    }
  }

  .confirm-btn-wrap {
    position: sticky !important;
    width: 100% !important;
    bottom: 0 !important;
    max-width: 480px !important;
    left: unset !important;
    transform: unset !important;
  }
}