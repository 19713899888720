.store-locator-wrap {
  padding: 10px;
  min-height: calc(100vh - 76px);

  .store-details {
    width: 100%;
    background-color: #fcfcfcbf;
    position: relative;
    margin-bottom: 15px;
    border-radius: 6px;
    padding: 10px 10px 5px;
    border: 1px solid #fcfafb;
    box-shadow: 0px 5px 10px #e8e8e8;
    transition: all ease-out 0.3s;
    letter-spacing: 0.5px;
    line-height: 17px;
    .name {
      font-size: 16px;
      letter-spacing: 0.2px;
      font-weight: 600;
      text-transform: uppercase;
      padding-bottom: 7px;
    }
    .information-wrap {
      display: grid;
      grid-template-columns: auto 5fr;
      align-items: center;
      padding: 7px 0px;

      .containt {
        padding: 0 5px 0 14px;
        text-align: left;
        font-family: var(--primary-font);
        font-size: 13px;
      }

      .right-align {
        float: right;
        padding-right: 10px;
      }

      .gap {
        padding-right: 20px;
      }
    }
  }
}
