.background-wrapper {
  height: calc(100vh - calc(100vh - 100%));
  background: white;

  .background-image {
    height: 100%;
    background: url(/assets/images/feedback/bg.jpg);
    background-repeat: no-repeat;
  }

  .feedback-container {
    // position: absolute;
    // top: 13%;
    width: 100%;
  }

  .feedback-page-wrap {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 14px 14px 0;
    background-color: white;
    margin: 0px 10px;
    border-radius: 5px;
    box-shadow: 0px 2px 11px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    margin-bottom: 80px;

    .feedback-wrap {
      width: 100%;
      margin: auto;
      text-align: center;

      .feedback-wrap-outer {
        margin-bottom: 27px;
        border-bottom: 1px solid #80808045;
        padding-bottom: 14px;
      }

      .subtext {
        margin: 0px auto 20px;

        strong {
          font-size: 17px;
        }
      }

      .requestMsg {
        font-size: 15px;
        margin-bottom: 20px;
      }

      .highlightName {
        margin: unset;
        font-weight: 600;
      }

      .remark {
        width: 100%;
        background-color: #efefef;
        border: unset;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 15px;
        font-family: var(--primary-font);
        font-weight: 500;
      }

      // .feedback-submit {
      //   border: unset;
      //   outline: unset;
      //   border-radius: 5px;
      //   padding: 10px 50px;
      //   background-color: var(--primary-color);
      //   color: white;
      //   font-weight: 500;
      //   width: 60%;
      //   margin-top: 3%;
      // }

      .overallRatting {
        border-radius: 5px;
        padding: 14px 0px;
        margin-bottom: 5%;
        color: var(--primary-color);
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
          0 2px 2px 3px rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      }
    }

    .content-wrap {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
      text-align: center;
      padding: 10px 0px;

      .criteria {
        text-align: left;
        font-family: var(--primary-font);
        // padding-left: 14px;
      }
    }

    .remarkTitle {
      text-align: left;
      padding: 5px 0px;
    }

    .font-ifAny {
      font-size: 11px;
    }

    .feedback-c-main-wrap {
      font-family: var(--primary-font);
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.3px;
      width: 100%;
      // height: 91vh;
      padding: 80px 0;
      background-color: white;
      color: #3f3d55;

      .feedback-c-wrap {
        width: fit-content;
        margin: auto;
        text-align: center;
        // padding: 0 10%;

        .submit-logo {
          width: 100%;
        }

        .submit-status {
          font-weight: 600;
          font-size: 15pt;
          margin-top: 20%;
          margin-bottom: 5%;
        }

        .submit-subtext {
          font-size: 10pt;
          padding: 5px 0;
        }

        .submit-button {
          border: unset;
          outline: unset;
          border-radius: 5px;
          padding: 10px 50px;
          margin-top: 10%;
          background-color: var(--primary-color);
          color: white;
          font-weight: 500;
        }
      }
    }
  }

  .expert-service-name-wrap {
    text-align: left;

    .expert-name {
      font-size: 18px;
      line-height: 20px;
      margin: 13px 0 5px;

      &:after {
        border-radius: 8px;
        width: 80px;
        height: 2px;
        content: '';
        background: var(--primary-color);
        display: block;
      }
    }

    .service-name {
      display: inline-block;
      width: auto;
      font-family: var(--primary-font);
      font-size: 13px;
    }

    .service-category {
      display: inline-block;
      font-size: 15px;
      width: auto;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.3px;
      font-family: var(--primary-font);
    }
  }

  .feedback-submit {
    max-width: 480px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 10px;
    width: 100%;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    text-align: center;
    align-items: center;
    background-color: white;
    box-shadow: 0px 2px 11px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

    button {
      border: unset;
      outline: unset;
      border-radius: 5px;
      padding: 10px 50px;
      background-color: var(--primary-color);
      color: white;
      font-family: var(--primary-font);
      font-weight: 500;
      width: 45%;
      box-shadow: 0px 5px 10px var(--primary-color-alpha);
      margin-left: 10px;
      font-size: 14px;
      letter-spacing: 0.5px;
      font-weight: 500;

      &:active {
        background-color: var(--primary-color-alpha);
        box-shadow: 0 2px 10px var(--primary-color-alpha);
        transform: scale(1.04);
      }

      &.skip {
        width: auto;
        padding: 10px 20px;
        background-color: white;
        color: var(--primary-color);
        box-shadow: none;
        border: 1px solid var(--primary-color-alpha);
        border-radius: 5px;
      }
    }
  }
}