.cart-page-wrap {
  width: 100%;
  position: relative;
  float: left;
  background: unset;
  min-height: calc(100vh - 175px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px 0;
  @include hide-scrollbar;

  .page-heading {
    width: 100%;
    float: left;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;
    padding: 15px;
    font-family: var(--primary-font);
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    position: fixed;
    top: 0;
    z-index: 1;
    background: white;
    background: linear-gradient(
      102.83deg,
      rgba(240, 238, 255, 0.28) -98.71%,
      #fff 103.17%
    );
    -webkit-backdrop-filter: saturate(180%) blur(7px);
    backdrop-filter: saturate(180%) blur(7px);
    max-width: 480px;

    .icon {
      background-color: #dee1ec;
      border-radius: 15px;
      color: var(--primary-color);
      box-shadow: #ebedf0ba 2px 3px 11px 0px;
      margin-right: 12px;
      width: 30px;
      height: 30px;
      padding: 5px;
    }
  }

  .checkout-btn-wrap {
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    width: 100%;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      102.83deg,
      rgba(240, 238, 255, 0.28) -98.71%,
      #fff 103.17%
    );
    -webkit-backdrop-filter: saturate(180%) blur(7px);
    backdrop-filter: saturate(180%) blur(7px);
    max-width: 480px;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;

    .icon-wrap {
      box-shadow: 0 2px 10px var(--primary-color-alpha);
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--primary-color);
      border-radius: 4px;
      color: var(--primary-text-color);
      padding: 6px 11px 6px 17px;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.3px;

      .icon {
        color: var(--primary-text-color);

        svg {
        }
      }

      transition: all ease-out 0.3s;

      &:active {
        box-shadow: 0 2px 10px var(--primary-color-alpha);
        transition: all ease-out 0.3s;
        transform: scale(1.04);
      }
    }

    .total-wrap {
      .title {
        font-size: 15px;
        font-weight: 600;
      }

      .value {
        font-size: 15px;
        padding-left: 6px;
        font-weight: 500;
      }
    }
  }

  .itemslistcover {
    width: 100%;
    float: left;
    padding: 0 15px;
    padding-bottom: 65px;

    .horizontal-product-card-wrap {
      position: relative;

      .out-of-stock {
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-weight: 600;
      }

      .product-cover {
        padding: 10px;

        .prod-left {
          width: auto;
          height: auto;

          // .prod-img-cover {
          //   width: 90px;
          //   height: 80px;
          // }
        }

        .prod-right {
          width: calc(100% - 110px);
          float: right;
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          height: 100%;
          align-items: flex-start;

          .prod-name {
            margin: 0 0 16px;
          }
        }

        .quantity-price-wrap {
          width: 100%;
          display: block;
          position: unset;

          .price-wrap {
            position: unset;
            width: auto;
            float: left;
          }

          .itemcounterinner {
            float: right;
            width: auto;

            .counterbuttons {
              min-width: unset;
            }
          }
        }
      }
    }
  }

  .itemlistrow {
    width: 100%;
    float: left;
    margin-bottom: 5px;
  }

  .itemcounter {
    width: 100%;
    float: left;
  }

  .itemcounter {
    width: 100%;
    float: left;
    margin-top: 4px;
    margin-bottom: 10px;
  }

  .itemcounterinner {
    float: right;
    width: auto;
  }

  .addtocartbtn {
    width: auto;
    float: right;
    background-color: var(--primary-color);
    color: #fff;
    height: 30px;
    border-radius: 5px;
    line-height: 28px;
    padding: 0 10px;
    cursor: pointer;
  }

  .counterbuttons {
    width: 100%;
    float: left;
    min-width: 100px;
  }

  .countnum {
    float: left;
    width: 30px;
    text-align: center;
    font-size: 18px;
    line-height: 25px;
  }

  .countclick {
    width: 25px;
    height: 25px;
    float: left;
    border: 1px solid #dee1ec;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 500;
    color: var(--primary-color);
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e6e7e8;
    box-shadow: #ebedf0ba 2px 3px 11px 0px;
    filter: drop-shadow(2px 1px 3px rgba(171, 161, 165, 0.152));
  }

  .itemname {
    float: left;
    width: 75%;
    font-size: 15px;
    text-transform: capitalize;
  }

  .variation-name {
    width: auto;
    display: inline-block;

    span {
      font-weight: 500;
      color: var(--primary-color);
    }
  }

  .itemprice {
    width: 24%;
    float: right;
    text-align: right;
    font-size: 15px;
    margin-top: -2px;
  }

  .subtotalsection {
    width: 100%;
    float: left;
    margin-bottom: 5px;
  }

  .subttlsecname {
    float: left;
    width: 50%;
    font-size: 14px;
    font-family: var(--primary-font);
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.3px;
    margin-bottom: 3px;
  }

  .subttlsecprice {
    float: right;
    width: 50%;
    font-size: 14px;
    font-family: var(--primary-font);
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.3px;
    margin-bottom: 3px;
    display: flex;
    justify-content: right;
    align-items: flex-start;

    .info {
      margin-left: 5px;
      width: 20px;
      height: 20px;
      margin-bottom: 3px;
      color: var(--primary-color-alpha);

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .emptyCart-main-wrap {
    font-family: "Poppins", sans-serif;
    width: 100%;
    height: 88vh;
    padding: 14px;
    color: #3f3d55;

    .emptyCart-wrap {
      width: fit-content;
      margin: auto;
      text-align: center;
      // padding: 20% 10% 0;
      height: calc(100vh - 97px);
      position: relative;
      width: 100%;

      float: left;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .cart-logo {
        position: relative;

        svg {
          width: 150px;
          height: 150px;
          color: var(--primary-color);
        }

        .line1 {
          position: absolute;
          width: 10px;
          height: 10px;
          top: 60%;
          left: 40%;
          border-radius: 50%;
          background-color: var(--primary-color);
          transform: translateY(-50%);
        }

        .line2 {
          position: absolute;
          width: 10px;
          height: 10px;
          top: 60%;
          left: 54%;
          border-radius: 50%;
          background-color: var(--primary-color);
          transform: translateY(-50%);
        }

        .line3 {
          position: absolute;
          width: 43px;
          height: 37px;
          top: 82%;
          left: 37%;
          border-radius: 52%;
          border: 3px solid var(--primary-color);
          transform: translateY(-50%);
          border-color: var(--primary-color) transparent transparent transparent;
        }
      }

      .cart-status {
        font-weight: 600;
        letter-spacing: 0.2px;
        padding: 15px 0 0;
        font-size: 16px;
      }

      .cart-subtext {
        font-size: 15px;
        padding: 15px;
        font-weight: 500;
      }

      .cart-button {
        border: unset;
        font-size: 15px;
        outline: unset;
        border-radius: 5px;
        padding: 10px 50px;
        margin-top: 10%;
        background-color: var(--primary-color);
        color: var(--primary-text-color);
        font-weight: 600;
      }
    }

    .order-confirmation-checkout {
      height: unset;
    }

    .order-details-wrap {
      padding: 16px;
      border-radius: 6px;
      background: rgba(255, 255, 255, 0.28);
      box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
      border: 1px solid rgba(255, 255, 255, 0.3);
      // max-height: 20%;
      // overflow: auto;
    }

    .heading {
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .item-wrap {
      border-bottom: 1px solid #e6e7e8;
      margin-bottom: 10px;
      padding-bottom: 6px;

      .name {
        width: 70%;
        display: inline-block;
        float: left;
      }

      .price {
        width: 30%;
        display: inline-block;
        float: right;
        text-align: right;
      }

      .variation-wrap {
        .name {
          color: var(--primary-color);
        }
      }
    }

    .order-total {
      font-size: 15px;
      margin-top: 5px;
    }

    .cart-button-wrap {
      text-align: center;
      background-color: white;

      .cart-button {
        border: unset;
        outline: unset;
        border-radius: 5px;
        padding: 10px 50px;
        margin: 16px 0;
        background-color: var(--primary-color);
        color: white;
        font-weight: 500;
        // position: fixed;
        // bottom: 10px;
        // left: 95px;
      }
    }
  }

  .sticky {
    position: sticky;
    bottom: 0;
  }
}
