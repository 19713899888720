.checkout-page-wrap {
  padding: 10px 15px;

  .icon-wrap {
    width: 27px;
    height: 27px;
    margin-right: 10px;
    background: #dee1ec8f;
    padding: 6px;
    border-radius: 15px;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  .page-heading {
    float: unset;
    padding: 15px 0;
  }

  .glass-card {
    background: #ffffff9c;
    padding: 12px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: #ebedf0ba 2px 3px 11px 0px;
    transition: all ease-out 0.3s;
    border: 1px solid #dee1ec9c;
  }

  .sub-heading {
    font-size: 13px;
    font-family: var(--primary-font);
    color: dimgray;
    padding-bottom: 10px;
    color: var(--primary-color);
    letter-spacing: 0.3px;
  }

  .guest-details-wrap {
    position: relative;

    .edit-user-icon {
      cursor: pointer;
      position: absolute;
      top: 4px;
      right: 4px;
      width: 30px;
      height: 30px;
      //   background: #dee1ec;
      border-radius: 5px;
      padding: 5px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.2px;

    .username {
      margin-bottom: 3px;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.2px;
    }
  }

  .order-type-wrap {
    .order-type {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .type-btn {
        display: inline-block;
        text-align: center;
        padding: 7px;
        background-color: #ffffff;
        border-radius: 4px;
        color: black;
        border: 1px solid #dee1ec;
        border-radius: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.2px;
        width: 110px;
        margin-right: 10px;
        box-shadow: 0px 0px 0.9px #dee1ec;

        transition: all ease-out 0.3s;

        &.active {
          color: var(--primary-color);
          transition: all ease-out 0.3s;
        }
      }
    }
  }

  .address-wrap {
    .add-new-btn {
      float: right;
      width: auto;
      color: var(--primary-color);
      text-decoration: underline;
      text-decoration-style: dotted;
    }

    .add-type-details {
      width: 100%;
      position: relative;
      padding: 10px;
      margin-bottom: 0px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .type-icon-wrap {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .type-name {
        padding-right: 8px;
        font-size: 13px;
        letter-spacing: 0.2px;
        font-weight: 600;
      }

      transition: all ease-out 0.3s;

      &.active {
        transition: all ease-out 0.3s;
        border-color: var(--primary-color);

        svg {
          color: var(--primary-color);
        }
      }
    }

    .address-details {
      padding-left: 36px;

      .line {
        font-size: 13px;
        font-family: var(--primary-font);
      }

      .edit-address-icon {
        cursor: pointer;
        filter: drop-shadow(4px 2px 6px #dee1ec);
        position: absolute;
        top: 4px;
        right: 0px;
        width: 33px;
        height: 33px;
        border-radius: 5px;
        padding: 5px;
      }
    }

    .no-address {
      position: absolute;
      top: 100px;
      width: 300px;
      left: 0;
      text-decoration: underline;
      font-size: 12px;
      color: var(--primary-color);
      letter-spacing: 0.5px;
      line-height: 30px;
    }
  }

  .order-btns-wrap {
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    padding: 15px;
    width: 100%;
    border-radius: 10px 10px 0 0;
    z-index: 2;
    max-width: 480px;
    bottom: 0;
    right: 0;
    background: linear-gradient(102.83deg,
        rgba(240, 238, 255, 0.28) -98.71%,
        #fff 103.17%);
    // -webkit-backdrop-filter: saturate(180%) blur(7px);
    // backdrop-filter: saturate(180%) blur(7px);
    max-width: 480px;

    .order-btns {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;

      .proceedbtn {
        width: 40%;
        height: 50px;
        margin: unset;
        display: inline-block;
        float: left;
        background-color: var(--primary-color);
        border-radius: 5px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: var(--primary-text-color);
        letter-spacing: 1px;
        padding: 12px;
      }

      .proceedbtn:last-of-type {
        justify-content: flex-end;
        width: 60%;
        margin-left: 10px;
      }

      .checkout-btn {
        justify-content: flex-end;
        width: 100% !important;
        margin-top: 15px;
      }
    }
  }

  .instlab {
    width: 100%;
    float: left;
    border: none;
    border-bottom: 1px dotted #999999;
    padding-bottom: 3px;
    margin-bottom: 5px;
    line-height: 28px;
    letter-spacing: 0.5px;

    &:focus {
      outline: none;
    }
  }

  .summary-details-wrap {
    width: 100%;
    float: left;

    .cart-items-list {
      padding-bottom: 5px;
      margin-bottom: 10px;
      border-bottom: 1px dashed gainsboro;

      .item-details {
        // display: flex;
        // justify-content: flex-start;
        // align-items: center;
        font-size: 12px;
        letter-spacing: 0.2px;
        margin-bottom: 10px;

        .item-name {
          width: auto;
          float: left;
          max-width: 80%;
        }

        .item-price {
          float: right;
          width: auto;
        }
      }
    }

    .total-wrap {
      .price-wrap {
        font-size: 12px;
        letter-spacing: 0.2px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        font-weight: 500;

        .type {
          width: 70%;
          text-align: right;
        }

        .value {
          text-align: right;
          width: 30%;
        }

        &.grand-total {

          .type,
          .value {
            font-weight: 600 !important;
          }
        }
      }
    }
  }

  .instruction-details-wrap {
    width: 100%;
    float: left;
    margin-bottom: 70px;
  }
}
form.stripePaymentForm {
  .stripeConfirmBtn {
    margin-top: 10px;
    display: inline-flex;
    justify-content: center;

    .MuiCircularProgress-root {
      color: inherit;
    }
  }
}