.body-text {
  padding: 10px 0 20px;
}

.address-toggle-wrap {
  padding: 19px;
  font-size: 18px;
  font-weight: 600;

  .tab {
    width: auto;
    display: inline-block;
    margin-right: 16px;
    text-align: center;
    padding: 0 5px;

    &.active {
      color: var(--primary-color);
      transition: all ease-out 0.3s;
      border-bottom: 1px solid;
    }
  }
}

.error {
  color: red;
}

.address-modal {
  text-align: center;
  width: 100%;
  float: left;
  position: relative;

  .heading {
    padding-bottom: 30px !important;
  }

  .top-icon {
    width: 25% !important;
    margin-bottom: 10px !important;
  }

  .input-wrap {
    margin: 0 0 13px;
    position: relative;

    .error {
      font-size: 12px;
    }

    span {
      &.label {
        font-family: var(--primary-font);
        color: #b7bac1;
        font-size: 11px;
        display: block;
        padding: 0 6px;
        padding-bottom: 3px;
        width: auto;
        background: #ffffff;
        position: absolute;
        top: -6px;
        z-index: 1;
        left: 5px;
      }
    }

    input {
      width: 100%;
      background: #ffffff82;
      border: none;
      border-radius: 5px;
      padding: 11px;
      outline: none;
      border: 1px solid #dee1ec;
      color: dimgray;
      font-size: 13px;
      font-family: var(--primary-font);
      letter-spacing: 0.3px;

      &:focus,
      &:focus-visible {
        transition: all ease-out 0.3s;
        border-color: var(--primary-color) !important;
      }

      &.error {
        border-color: red !important;
      }
    }
  }

  .save-add-btn {
    margin-top: 14px;
    display: inline-block;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }

  .text {
    text-align: left;
    padding-left: 3px;
    font-size: 12px;
    color: black;
    font-family: var(--primary-font);
  }

  .address-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    .input {
      font-size: 12px;
      width: 100%;
      color: black;
      font-family: var(--primary-font);
    }
  }
}

.paymode-list-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 20px 0 50px;
  transition: all ease-out 0.3s;

  .paymode-details-wrap {
    transition: all ease-out 0.3s;
    padding: 4px;

    &.active {
      transition: all ease-out 0.3s;
      outline: 2px solid var(--primary-color);
      border-radius: 4px;
      background: var(--primary-color-alpha);
      transition: var(--transition);
    }
  }

  .paymode-details {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dee1ec;
    padding: 10px;
    border-radius: 4px;

    &.CCAvenue {
      background: #18212e;
      padding: 14px;
    }

    img {
      width: 82%;
      height: 100%;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}