html,
body {
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Karla, Poppins,
    sans-serif;
  margin: 0;
  // overflow-y: inherit !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all ease-out 0.3s;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  // overflow-x:hidden !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: var(--primary-font);
  // color: #303641;
  // transition: all ease-out 0.3s;
  font-weight: 400;
}