@import 'abstracts/variables';
@import 'abstracts/mixins';
@import 'abstracts/functions';
@import 'base/base';
@import 'base/typography';
@import 'base/common';

//pages styling
@import 'pages/Inactive_store';

//components styling
@import 'components/elements/Icon/Icon';
@import 'components/elements/imageSlider/imageSlider';
@import 'components/elements/horizontalItem/horizontalItem';
@import 'components/elements/customeImageSlider/customeImageSlider';
@import 'components/layouts/Default/Default';

// modules styling
@import 'components/modules/Header/Header';
@import 'components/modules/circularGrid/circularGrid';
@import 'components/modules/horizontalProductCard/horizontalProductCard';
@import 'components/modules/horizontalSquareGrid/horizontalSquareGrid';
@import 'components/modules/squareGrid/squareGrid';
@import 'components/modules/topScrolleingNavigation/topScrolleingNavigation';
@import 'components/modules/userRegistration/userRegistration';
@import 'components/modules/userQueryModal/userQueryModal';
@import 'components/modules/loader/Loader';
@import 'components/modules/stickyCart/StickyCart';
@import 'components/modules/productsWithCategory/ProductsWithCategory';
@import 'components/modules/confirmationModal/confirmationModal';
@import 'components/modules/pdpModal/pdpModal';
@import 'components/modules/servicePdpModal/servicePdpModal';
@import 'components/modules/footer/Footer';
@import 'components/modules/sliderDialog/sliderDialog.scss';
@import 'components/modules/orderDetailModal/orderDetailModal';
@import 'components/modules/filterModal/filterModal';
@import 'components/modules/pwa/PWA';
@import 'components/modules/servicesCartModal/servicesCartModal';
@import 'components/modules/servicesCartModal/appointmentServiceModal';
// templates styling
@import 'components/templates/Home/Home';
@import 'components/templates/tenantPage/tenantPage';
@import 'components/templates/productPdp/productPdp';
@import 'components/templates/categories/categories';
@import 'components/templates/categories/VerticalCategoryPage.scss';
@import 'components/templates/servicePdp/servicePdp';
@import 'components/templates/staffPdp/staffPdp';
@import 'components/templates/cartPage/CartPage';
@import 'components/templates/imageGalleryModal/imageGalleryModal';
@import 'components/templates/feedback/feedback';
@import 'components/templates/invoice/invoice';
@import 'components/templates/orderConfirmation/orderConfirmation';
@import 'components/templates/myorders/myorders';
@import 'components/templates/profile/profile';
@import 'components/templates/privacy/privacy';
@import 'components/templates/addressModal/AddressModal';
@import 'components/templates/search/search';
@import 'components/templates/appointment/appointment';
@import 'components/templates/checkout/CheckoutPage';
@import 'components/templates/storeLocator/storeLocator';
@import 'components/image-gallery.scss';

//slider gallery css
// @import '~react-image-gallery/styles/scss/image-gallery.scss'; //styles of image-gallery
@import './components/nprogress'; //styles of nprogress
@import './components/date-picker'; //styles of date picker
@import '~node_modules/react-phone-input-2/lib/style.css';