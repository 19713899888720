.footer-wrap {
  color: rgb(0, 0, 0);
  padding: 50px 14px 50px 25px;
  background-size: 102%;
  background-repeat: no-repeat;
  position: relative;
  background-image: unset;
  font-size: 14px;
  // background: #0b2135;

  background: linear-gradient(180deg, #b0b6ff00, var(--primary-color) 140.2%);

  .padding-top-bottom {
    padding: 2px 0px;
  }

  .getInTouch {
    font-size: 15px;
    // letter-spacing: 0.3px;
    margin: 10px 0;
    font-weight: 600;
    width: auto;
    display: inline-block;
    margin-bottom: 0;
  }

  .information-wrap {
    display: grid;
    grid-template-columns: auto 5fr;
    align-items: center;
    padding-bottom: 10px;

    .containt {
      padding: 0 5px 0 14px;
      display: flex;
      justify-content: flex-start;

      .store-details {
        padding-right: 10px;
        text-transform: capitalize;

        &:nth-child(1) {
          border-right: 1px solid white;
          margin-right: 10px;
        }
      }

      .name {
        // font-weight: 600;
        font-family: "poppins-regular";
        text-transform: capitalize;
        padding-right: 6px;
        border-right: 1px solid;
        margin-right: 6px;

        &:last-child {
          border: unset;
        }
      }

      a {
        width: auto;
        height: auto;
      }
    }

    .right-align {
      float: right;
      padding-right: 10px;
    }

    .gap {
      padding-right: 20px;
    }
  }

  .social-wrap {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .social-icon-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .social-icon {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    margin-right: 9px;
    padding: 4px;

    svg {
      width: 100%;
      height: 100%;
      color: var(--primary-color);
    }

    .insta {
      color: deeppink;
      width: 30px;
      height: 30px;
    }

    .facebook {
      color: #4267b2;
    }

    .youtube {
      color: #ff0000;
    }

    .whatsapp {
      color: #25d366;
    }

    &.floating-whatsapp {
      position: fixed;
      bottom: 0;
      left: 46%;
      background: unset;
      box-shadow: unset;
      padding: unset;
      border: unset;
      margin: unset;
      right: 0;
      justify-content: flex-end;
      align-items: flex-end;
      width: 100%;
      transform: translateX(-50%);
      max-width: 480px;

      svg {
        width: 55px;
        height: auto;
        margin-right: 5px;
      }
    }
  }

  .store-timing-wrap {
    padding: 10px 0;
    margin-right: 10px;
    margin-top: 20px;
    border-radius: 16px;
    text-align: center;
    border: unset;
    box-shadow: unset;
    backdrop-filter: unset;

    .heading {
      font-size: medium;
      font-weight: 600;
    }
  }
}

.copyright-wrp {
  // background-color: var(--primary-color);
  // color: black;
  padding: 30px 0px 10px;
  text-align: center;
  // transform: translateY(-1px);
  // span {
  //   font-family: var(--primary-font);
  // }
}

.confirmation-modal-wrap {
  .backdrop-modal-wrapper {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .backdrop-modal-content {
    color: #1a1a1a;
    padding: 20px;
    border-radius: 12px 12px 0 0;
    width: 100%;
    margin: auto;
  }

  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .member-modal {
    .body-text-timing {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: bold;
      color: #1a1a1a;
      padding-left: 5px;
    }

    .timings-list {
      list-style-type: none;
      padding: 0;

      .timing-item {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        font-size: 16px;
        align-items: center;
        padding-left: 5px;
        padding-right: 5px;

        .day {
          color: #3d3d3d;
        }

        .today-label {
          background-color: #f17c7c;
          color: #030303;
          padding: 4px 8px;
          border-radius: 12px;
          font-size: 12px;
          margin-right: 40px;
          border-style: solid;
          border-color: #ed5252;
        }

        .time {
          color: #3d3d3d;
          font-weight: 500;
        }

        &.current-day {
          background-color: #e2eaec;
          border-radius: 4px;
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }
  }
}
