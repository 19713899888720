.products-category-wrap {
  margin: 30px 0 20px;

  .cat-name {
    font-size: 17px;
    padding-left: 20px;
    font-weight: 500;
    margin: 20px 0 5px;
    font-family: var(--primary-font);
    letter-spacing: 0.2px;
  }

  .prod-img-cover {
    box-shadow: 0px 5px 20px #9e9e9e87;
  }

  .services-list-wrapper {
    padding: 0 10px;
  }

  .service-list-cover {
    width: 100%;
    float: left;
  }
}

/* common */
.ribbon {
  width: 300px;
  height: 45px;
  overflow: hidden;
  position: absolute;
  left: 0px;
  top: 0px;
  border-top-left-radius: 8px;

  .tag {
    position: absolute;
    width: auto;
    height: 24px;
    display: flex;
    padding-left: 15px;
    background-color: var(--primary-color);
    box-shadow: 1px 4px 17px var(--primary-color-shadow);
    color: #ffffff;
    /* text-shadow: 0 1px 1px rgb(0 0 0 / 12%); */
    text-align: center;
    letter-spacing: 0.2px;
    font-size: 10px;
    justify-content: center;
    align-items: center;
    font-style: italic;

    &:after {
      width: 0;
      height: 0;
      border: 12px solid transparent;
      position: absolute;
      content: '';
      border-top-color: var(--primary-color);
      right: -24px;
      top: 0;
      border-left-color: var(--primary-color);
    }

    &:before {
      width: 0;
      height: 0;
      border: 12px solid transparent;
      position: absolute;
      content: '';
      border-bottom-color: var(--primary-color);
      right: -24px;
      top: 0px;
      // z-index: 13;
    }
  }
}

.services-list-wrapper {
  &.product-list-wrapper {
    &.grid-view {
      display: flex;
      flex-wrap: wrap;
      // gap: 1%;
      align-items: flex-start;
      padding: 5px;
      gap: 3px;

      // justify-content: space-between;
      .service-list-cover {
        width: 49.5%;
        float: unset;
        height: 100%;
        background: unset;

        .horizontal-product-card-wrap {
          .product-cover {
            flex-direction: column;
            gap: 5px;
            // padding: 10px;
          }

          .prod-left {
            width: auto;
            min-height: auto;

            .prod-img-cover {
              box-shadow: unset;
              width: auto;
            }
          }

          .prod-right {
            padding: 0 7px;

            .prod-name {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .prod-sizeprice {
              margin: 4px 0;
            }
          }
        }

        &.subsubcat-wrap {
          width: 100%;
          float: left;
          background: #ffffff9c;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }
      }
    }
  }
}