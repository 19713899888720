.guided-label {
  font-size: 12px;
  color: #6f747ead;
}

.services-cart-modal-details {
  &.appointment-service-form {
    max-height: 90vh;
    height: auto;
    min-height: 45vh;

    .date-picker-wrap {
      .date-picker-button {
        width: 100%;
        border-radius: 5px;
        border: unset;
        outline: none;
        padding: 0 7px;
        padding-left: 13px;
        font-size: 14px;
        letter-spacing: 0.2px;
        box-sizing: border-box;
        background: white;
        text-align: left;
        color: black;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
      }

      .react-datepicker__navigation {
        //   display: none;
      }

      .react-datepicker__portal .react-datepicker__current-month,
      .react-datepicker__portal .react-datepicker-time__header {
        display: none;
      }

      .react-datepicker-popper[data-placement^="top"]
        .react-datepicker__triangle,
      .react-datepicker-popper[data-placement^="bottom"]
        .react-datepicker__triangle {
        display: none;
      }

      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        //   display: none;
      }

      .date-picker-button {
        padding: unset;
        padding-left: 10px;
      }
    }

    .card {
      padding: 10px 2px;
      width: 100%;

      .label {
        padding-left: 10px;
      }
    }

    .slot-wrap {
      width: calc(100% / 4 - 10px);
      transition: all ease-out 0.3s;
      padding: 6px;
    }

    .unavailable-data {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      color: #6f747ead;
    }

    .error {
      color: red !important;
    }

    .btn-wrap {
      margin: unset;
    }
  }
}

.staff-list {
  position: relative;

  .label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  .staff-list-wrapper {
    position: absolute;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0 1px 8px 2px #6f747ead;
    padding: 10px;
    top: 46px;
    z-index: 4;
    left: 2px;
    overflow-y: auto;
    height: calc(46vh - 200px);

    .item-details {
      background: rgba(50, 55, 89, 0.08);
      box-shadow: 0 1px 4px rgba(221, 226, 235, 0.2901960784);
      width: 100%;
      min-width: 200px;
      border-radius: 5px;
      margin-bottom: 10px;
      padding: 9px;
      position: relative;
      border: 1px solid rgba(222, 225, 236, 0.5803921569);

      &.active {
        border-color: var(--primary-color);
      }
    }

    .salut-list-details {
      width: 100%;
      min-width: auto;
    }
  }
}

.slot-time-outer {
  height: calc(70vh - 200px);
  overflow: auto;

  // white-space: nowrap;
  .sub-heading {
    position: sticky;
    left: 0;
    top: 0;
  }

  .slot-wrap {
    display: inline-block;
    width: calc(100% / 3 - 10px);
    padding: 7px;
    margin: 5px;
    border-radius: 5px;
    text-align: center;
    border: 1px solid #ddd;
    transition: all ease-out 0.3s;
    font-size: 14px;
    color: black;
    letter-spacing: 0.5px;

    &.active {
      background: var(--primary-color);
      color: var(--primary-text-color);
      transition: all ease-out 0.3s;
      position: sticky;
      left: -10px;
      right: 0;
    }

    &.unavailable {
      background: #dee1ec;
      pointer-events: none;
      user-select: none;
    }
  }

  padding-top: 7px;
}

.slot-error {
  font-size: 11px;
  padding: 7px;
}
