.query-modal-wrap {
    max-height: 90vh;
    overflow: auto;

    .body-text {
        padding-right: 40px;
    }
    .input-wrap-with-label {
        position: relative;
        display: flex;
        margin-top: 15px;
        justify-content: flex-start;
        align-items: center;
        background: white;
        border: 1px solid #cccfd7;
        padding: 7px;
        border-radius: 5px;
        transition: all ease-out 0.3s;

        &.error {
            border: 1px solid red;
            color: red;
        }

        .label {
            color: #ffffffde;
            font-size: 20px;
            letter-spacing: 0.3px;
            position: absolute;
            right: 3px;
            top: 0;
        }

        .form-label {
            position: absolute;
            left: 3px;
            top: -23px;
            transition: all ease-out 0.3s;
            opacity: 0;
        }

        textarea,
        input {
            width: 100%;
            outline: none;
            color: black;
            letter-spacing: 0.3px;
            padding-left: 5px;
            line-height: normal;
            border: unset;
            font-size: 14px;
            max-height: 300px;

            &:focus,
            &:focus-visible {
                transition: all ease-out 0.3s;
                border-color: var(--primary-color) !important;
            }

            &.error {
                border-color: red !important;
            }

            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: rgba(0, 0, 0, 0.709);
                opacity: 1;
                /* Firefox */
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: black;
            }

            &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: black;
            }

            /* Chrome, Safari, Edge, Opera */
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            &[type=number] {
                -moz-appearance: textfield;
            }
        }

        &.active {
            margin-top: 34px;

            &:first-child {
                margin-top: 20px;
            }

            .form-label {
                transition: all ease-out 0.3s;
                opacity: 1;
                display: block;
            }
        }

    }

    .contact-note {
        padding: 16px 0;
        font-size: 14px;
        letter-spacing: 0.2px;

        a {
            color: blue;
            margin-left: 5px;
        }
    }

    .form-btn-wrap {
        padding: 10px 0 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}