/* submenu css starts */
.cat-navbar-cover {
  width: 100%;
  float: left;
  padding: 10px 0px;
  overflow-y: hidden;
  overflow-x: auto;
  position: sticky;
  top: 75px;
  left: 0;
  z-index: 7;
  width: 87%; // background-color: #e6e7e8;
  @include hide-scrollbar;
  /* margin-bottom: 30px; */
}

.cat-navbar-inner {
  display: inline-block;
  list-style: none;
  width: auto;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  @include hide-scrollbar;
}

.cat-nav-link {
  width: auto;
  display: inline-block;
  font-weight: 500;
  position: relative;
  color: #242222bf;
  border-radius: 20px;
  padding: 10px 12px;
  font-size: 13px;
  font-weight: 600;
  margin-left: 10px;
  letter-spacing: 0.1px;
  z-index: 1;
  background: white;
  border: 1px solid #fbfbfb;
  transition: all ease-out 0.3s;

  &.active {
    background: var(--primary-color);
    position: sticky;
    left: 10px;
    right: 10px;
    border: 1.5px solid var(--primary-color);
    color: var(--primary-text-color);
    z-index: 2;
    transition: all ease-out 0.3s;
  }
}

.cat-nav-link:hover span,
.cat-nav-link.active span {
  opacity: 1;
  transition: all ease-out 0.3s;
}

/* submenu css ends */