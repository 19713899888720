.gallery-modal-wrap {
  .gallery-wrap {
    width: 100%;
    .image-gallery {
      margin-bottom: 0;
    }
    .image-gallery-swipe {
      padding: unset;
    }
    .image-gallery-slide {
      height: 100%;
      max-height: unset;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .MuiDialog-paper {
    margin: 10px;
  }
}

// class for fullscreen

.fullscreen {
  .imageSlider {
    width: 430px;
    max-height: 480px;
  }
}

.MuiDialog-paperWidthSm {
  min-width: 100%;
  max-height: calc(100% - 64px);
}

.image-gallery-icon svg {
  color: #fff;
  width: 25px !important;
  height: 25px !important;
}

.image-gallery-icon svg polyline {
  color: #fff;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 5px 6px 2px 6px !important;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--primary-color) !important;
  border-radius: 50%;
  filter: drop-shadow(0 0px 1px #1a1a1a) !important;
}
