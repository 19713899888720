.servicepdpcontainer {
  width: 100%;
  float: left;

  .prodpdpbanner {
    width: 100%;
    float: left;
    // height: 350px;
  }

  .alice-carousel__dots {
    bottom: 50px !important;
  }

  .alice-carousel__dots-item:not(.__custom) {
    border: 0px solid transparent !important;
    width: 6px !important;
    height: 6px !important;
  }

  .alice-carousel__dots-item:not(.__custom):hover,
  .alice-carousel__dots-item:not(.__custom).__active {
    background-color: var(--primary-color) !important;
    border: 0px solid #000000 !important;
    width: 6px !important;
    height: 6px !important;
    margin-bottom: 0px;
  }

  .serv-pdp-details {
    width: 100%;
    float: left;
    border-radius: 20px 20px 0 0;
    box-shadow: 0 0 5px #777;
    margin-top: -15px;
    background-color: #ffffff;
    position: relative;
    z-index: 2;
    padding: 15px;
    // bottom: 0;
    // position: absolute;
  }

  .serv-pdp-servname {
    width: 100%;
    float: left;
    font-size: 14px;
    margin-bottom: 5px;
    text-transform: capitalize;
  }

  .serv-pdp-servtype {
    width: 100%;
    float: left;
    margin-bottom: 10px;
  }

  .serv-pdp-servtypename {
    width: 70%;
    float: left;
    font-size: 16px;
    font-weight: 600;
  }

  .serv-pdp-servtypetime {
    width: 30%;
    float: right;
    text-align: right;
    font-size: 13px;
  }

  .serv-pdp-servtypedesc {
    width: 100%;
    float: left;
    font-size: 11px;
    font-weight: 300;
    margin-bottom: 20px;
  }

  .serv-pdp-servtypedesc span {
    color: var(--primary-color);
    margin-left: 5px;
  }

  .serv-pdp-servoffertitle {
    width: 70%;
    float: left;
    font-size: 11px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .serv-pdp-servofferfor {
    width: 100%;
    float: left;
    margin-bottom: 5px;
  }

  .serv-pdp-servofferfornm {
    width: 70%;
    float: left;
    width: 70%;
    float: left;
    font-size: 11px;
    font-weight: 300;
  }

  .serv-pdp-servofferfornm.highlight {
    font-weight: 600;
  }

  .serv-pdp-servofferforpr {
    width: 30%;
    float: right;
    text-align: right;
    font-size: 13px;
    font-weight: 500;
  }

  .serv-pdp-servcombotitle {
    width: 100%;
    float: left;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .serv-pdp-servcombofor {
    width: 100%;
    float: left;
  }

  .serv-pdp-servcombofornm {
    width: 70%;
    float: left;
    font-size: 10px;
  }

  .serv-pdp-servcombofornm span {
    font-size: 11px;
    font-weight: 500;
  }

  .serv-pdp-servcomboforpr {
    width: 30%;
    float: right;
    text-align: right;
    font-size: 13px;
    font-weight: 500;
  }

  .variations-wrap {
    .staff-spl-heading {
      width: 100%;
      padding: 10px 0;
      font-weight: 500;
      font-size: 16px;
    }

    .staff-spl-desc {
      width: 100%;
      float: left;
      font-size: 11px;
      font-weight: 400;
      margin-bottom: 20px;
    }
  }
}
